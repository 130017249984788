import { useState } from 'react';
import logo from './logo.svg';
import timpilogo from './timpilogo.svg';
import './App.css';
import { Window as KeplrWindow } from "@keplr-wallet/types";
import ListDisplay from './ListDisplay'

declare global {
    interface Window extends KeplrWindow { }
}

let FirstRender: boolean = true;
let AlreadyPressedButton: boolean = false;
let WalletAddress: string = '';
let test1: string = "";


function App() {
    const [TokenList, setTokenList] = useState<[string, string][]>([]);

    const handleClick = () => {
        FetchNFTInfo();
    };

    if (FirstRender) {
        DetectKeplr();
        //AddChain();
        window.keplr?.enable("Neutaro");
        FirstRender = false;
    }
    const FetchNFTInfo = async () => {

        window.keplr?.enable("Neutaro");
        var key = await window.keplr?.getKey("Neutaro");
        var address = key?.bech32Address;
        if (address) {
            WalletAddress = address;
        }

        setTokenList([]);
        const queryMetadataNFTContractSmart: string = 'https://keplr.timpi.io/cosmwasm/wasm/v1/contract/neutaro14hj2tavq8fpesdwxxcu44rty3hh90vhujrvcmstl4zr3txmfvw9s9z4e5z/smart/'
        const toEncodeGetAllNFTs: string = '{"tokens":{"owner":"' + WalletAddress + '"}}'
        

	var encodedGetNFTs: string = encodeToBase64(toEncodeGetAllNFTs);

        const urlGetNFT = queryMetadataNFTContractSmart + encodedGetNFTs;
	//app.get('/',(req,res)=>{res.set('Access-Control-Allow-Origin', '*');
	//const responseGetNFT = await fetch(urlGetNFT,(req,res)=>{res.set('Access-Control-Allow-Origin', '*'));     
	const responseGetNFT = await fetch(urlGetNFT);
        const jsonDataGetNFT = await responseGetNFT.json();
        var tokens = jsonDataGetNFT.data.tokens;


        asyncForEach(tokens, async (tokenName: string) => {
            const add: string = '" } }';
            const toEncode: string = '{ "all_nft_info": { "token_id": "' + tokenName + add;

            var encoded = encodeToBase64(toEncode);

            const url = queryMetadataNFTContractSmart + encoded;
            const response = await fetch(url);
            const jsonData = await response.json();
  //          const description: string = jsonData.data.info.extension.description;
            const owner: string = jsonData.data.access.owner;
            const path: string = jsonData.data.info.token_uri;
            if (!AlreadyPressedButton) {
                //if (address === owner)
                    await addTuple(tokenName, path);
            }
        });

        async function addTuple(tokenName: string, path: string) {
            setTokenList(TokenList => [...TokenList, [tokenName, path]]);
        }
    };

   


    return (
        <div className="App">
            <header className="App-header">
		
		<img src={timpilogo} className="Timpi-logo HeaderFont" alt="logo"  />                
		<p  className="HeaderFont">$NTMPI and NFT on</p>
                <img src={logo} className="App-logo" alt="logo"  />

                <button onClick={AddChain} className="Button">
                   1. Add Neutaro Chain to Keplr
                </button>
                <button onClick={ShowWalletAddress} className="Button">
                   2. Show my Wallet Address
                </button>
		<p>&nbsp;</p>
		Check my wallet and show my NFTs
                <button onClick={handleClick} className="Button">
                    Show my NFTs
                </button>
	        <p  className="NFTfont">{WalletAddress}</p>
                <div>
                   <ListDisplay tuples={TokenList} />
                </div>
		<div className="ParaBellow">&nbsp;</div>
            </header>
        </div>
    );
}

async function DetectKeplr() {
    if (!window.keplr) {
        alert("Please install keplr extension")
    }
    else {
    }
}

function encodeToBase64(str: string): string {
    const base64 = btoa(str);
    return base64;
}
async function asyncForEach(array: any[], callback: any) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

async function ShowWalletAddress(){

	var key = await window.keplr?.getKey("Neutaro");
        var address = key?.bech32Address;
        if (address) {
            WalletAddress = address;
        }
	alert("Please copy this address:"+ WalletAddress);

}
async function GotoURL(){
window.location.href = 'https://timpi.io/NFT-marketplace/';
}

async function AddChain() {
    try {
        window.keplr?.experimentalSuggestChain({
            chainId: "Neutaro-1",
            chainName: "Neutaro",
            rpc: "https://rpc1.neutaro.tech/",
            rest: "https://api1.neutaro.tech/",
            bip44: {
                coinType: 118,
            },
            bech32Config: {
                bech32PrefixAccAddr: "neutaro",
                bech32PrefixAccPub: "neutaropub",
                bech32PrefixValAddr: "neutarovaloper",
                bech32PrefixValPub: "neutarovaloperpub",
                bech32PrefixConsAddr: "neutarovalcons",
                bech32PrefixConsPub: "neutarovalconspub",
            },
            currencies: [
                {
                    coinDenom: "neutaro",
                    coinMinimalDenom: "uneutaro",
                    coinDecimals: 6,
                },
            ],
            feeCurrencies: [
                {
                    coinDenom: "neutaro",
                    coinMinimalDenom: "uneutaro",
                    coinDecimals: 6,
                    gasPriceStep: {
                        low: 0.01,
                        average: 0.025,
                        high: 0.04,
                    },
                },
            ],
            stakeCurrency: {
                coinDenom: "neutaro",
                coinMinimalDenom: "uneutaro",
                coinDecimals: 6,
            },
        });

    } catch (exceptionVar) {
        alert(exceptionVar);
}
}
export default App;
