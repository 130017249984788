import React from 'react';


interface MyComponentProps {
    tuples: [string,string][];
}

const ListDisplay: React.FC<MyComponentProps> = ({ tuples }) => {
    return (
	<div style={{ gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20 ,display: "flex", alignItems: "center"}}>
            {tuples && Array.isArray(tuples) && tuples.map((tuple, index) => (             
		<div>
                    <p className="NFTfont">{tuple[0]}</p>
                    <a href={tuple[1]} target="_blank"><img src={tuple[1]} className="NFTPpic"></img></a>
                </div>
            ))}
        </div>
    );
};

export default ListDisplay;